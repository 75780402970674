<template>
  <div id="app" ref="appRoot">
    <top-bar v-if="!isHomePage" />
    <!--<site-menu v-else class="top-site-menu" />-->
    <router-view />
    <!-- <site-footer /> -->

  </div>
</template>


<script>
import TopBar from '@/components/TopBar.vue';
import SiteMenu from '@/components/SiteMenu.vue';
import SiteFooter from '@/components/SiteFooter.vue';



export default {
  components: {
    TopBar, SiteMenu, SiteFooter,
  },
  computed: {
    isHomePage(){
      return this.$route.name === 'Home'
    }
  },
  data:() => ({
    showFeedback: false,
    showJoinUs: false,
    siteName: process.env.VUE_APP_SITE_NAME,
  }),
  mounted(){
    this.$eventBus.$on('scrollToTop', () => {
      this.$scrollTo('body');
      window.parent.postMessage({
        scrollToTop: true,
      },"*");
    });
    this.$eventBus.$on('showFeedback', () => {
      this.showFeedback = true;
    });
    this.$eventBus.$on('showJoinUs', () => {
      this.showJoinUs = true;
    });

    if(window.location.href.indexOf('form=feedback') > -1){
      this.showFeedback = true;
    }
    if(window.location.href.indexOf('form=join-us') > -1){
      this.showJoinUs = true;
    }
    const observer = new ResizeObserver(function() {
      window.parent.postMessage({
        height: document.body.scrollHeight + 50,
      },"*");
    });
    observer.observe(this.$refs.appRoot);

    /* usage
    //In the parent document
    window.addEventListener('message', function(event){
      if (event.origin === 'https://freesearch.causeaction.com' && event.data) {
        var iFrameHeight = event.data.height; 
        var scrollToTop = event.data.scrollToTop;
        if(iFrameHeight){
          document.querySelector('#free_iframe').style.height = iFrameHeight+'px';
        }
        if(scrollToTop){
          window.scrollTo(0,60)
        }
      }
    }, false);
    */
  }
}
</script>

<style lang="scss" scoped>
.top-site-menu{
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}
@media screen and (max-width: 768px){
  .top-site-menu{
    top: 20px;
    right: 20px;
  }
}
</style>