<template>
  <div>
    <nav class="desktop-menu">
        <ul class="is-flex is-justify-content-flex-end">
            <li class="menu-item" v-for="(menuItem, i) in menuItems" :key="i">
                <router-link class="menu-item-link"
                    :class="[linkClass, i === menuItems.length-1 ? 'last': '']"
                    :to="menuItem.link">{{menuItem.text}}</router-link>
            </li>
        </ul>
    </nav>
    <div class="mobile-menu-button">
        <b-icon
            :class="linkClass"
            icon="menu"
            size="is-large"
            @click.native="showNavPanel = true">
        </b-icon>
    </div>
    <b-sidebar
      type="is-primary"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="true"
      v-model="showNavPanel"
    >

        <nav class="mobile-menu">
            <div class="has-text-right">
                <b-icon
                    class="has-text-white"
                    icon="close"
                    size="is-medium"
                    aria-label="close"
                    @click.native="showNavPanel = false">
                </b-icon>
            </div>
            <ul>
                <li class="menu-item mb-5" v-for="(menuItem, i) in menuItems" :key="i">
                    <router-link 
                        @click.native="showNavPanel = false"
                        class="menu-item-link has-text-white"
                        :class="[i === menuItems.length-1 ? 'last': '']"
                        :to="menuItem.link">{{menuItem.text}}
                    </router-link>
                </li>
            </ul>
        </nav>
    </b-sidebar>
  </div>
</template>

<script>
export default {
    name: "SiteMenu",
    props: {
        linkClass: {
            type: String,
            default: 'has-text-white'
        }
    },
    data: () => ({
        menuItems:[
        ],
        showNavPanel: false
    })
}
</script>

<style lang="scss" scoped>
    .menu-item-link{
        font-weight: 600;
        text-transform: uppercase;
    }
    .desktop-menu .menu-item-link{
        font-size: 16px;
        margin-right: 12px !important;
        &::after{
            margin-left: 12px !important;
            content: '|' !important; 
        }
        &.last{
            margin-right: 0 !important;
            &::after{
                margin-left: 0 !important;
                content: none !important;
            }
        }    
    }
    .mobile-menu-button{
        display: none;
        margin-right: 5px;
    }
    .mobile-menu{
        padding: 20px;
    }
    .mobile-menu .menu-item-link{
        font-size: 18px;
    }
    @media screen and (max-width:1500px){
        .desktop-menu{
            display: none;
        }
        .mobile-menu-button{
            display: block;
        }
    }
</style>