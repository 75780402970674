<template>
  <a target="_blank" rel="noopener noreferrer nofollow" :href="href">
    <slot></slot>
  </a>
</template>

<script>
export default {
    name: 'ExternalLink',
    props:{
        href:{
            type: String,
            default: ''
        }
    }
}
</script>